import React, { CSSProperties } from 'react';
import ezwork_footer from "../../assets/ezwork_footer.svg";
import cdit_footer from "../../assets/cdit_footer.svg";

const Footer = () => {
  return (
    <div style={styles.footerContainer}>
      <div style={styles.footerContent}>
        <div style={styles.logoContainer}>
          <img src={ezwork_footer} alt="ezWork Logo" style={styles.ezworkLogo} />
        </div>
        
        <div style={styles.contactInfo}>
          <p style={styles.institutionName}>
            VIỆN CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG CDIT
          </p>
          <p style={styles.address}>
            Tầng 3 nhà A1, Học viện Công nghệ Bưu chính Viễn thông, Km 10, 
            Đường Nguyễn Trãi, Hà Đông, Hà Nội
          </p>

          <div style={styles.footerFlex}>
            <div style={styles.poweredBy}>
              <p style={styles.poweredByText}>Power by</p>
              <img src={cdit_footer} alt="CDIT Logo" style={styles.cditLogo} />
            </div>
            
            <div style={styles.separator}></div>
            
            <div style={styles.contactDetails}>
              <p style={styles.contactItem}><span style={styles.contactLabel}>Hotline:</span> <span style={styles.contactValue}>093.626.7689</span></p>
              <p style={styles.contactItem}><span style={styles.contactLabel}>Fanpage:</span> <span style={styles.contactValue}>facebook.com/ezWorkService</span></p>
              <p style={styles.contactItem}><span style={styles.contactLabel}>Email:</span> <span style={styles.contactValue}>ezwork.vn@gmail.com</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  footerContainer: {
    width: '100%',
    padding: '16px 16px 24px 16px',
    boxSizing: 'border-box',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTop: '1px dashed transparent',
    borderImageSource: 'linear-gradient(to right, #FFFFFF, #DDEBF4, #FFFFFF)',
    borderImageSlice: 1,
    background: 'linear-gradient(180deg, #FFFFFF 68.1%, #DDE7F4 100%)',
  },
  footerContent: {
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '15px',
  },
  logoContainer: {
    marginBottom: '16px',
  },
  ezworkLogo: {
    height: '40px',
    width: 'auto',
    objectFit: 'contain',
  },
  contactInfo: {
    marginBottom: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  institutionName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0%',
    textAlign: 'center',
    color: '#1A5C8C',
    margin: '5px 0',
  },
  address: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0%',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: '#555',
    margin: '8px 35px 15px 35px',
    alignSelf: 'center',  
  },
  contactDetails: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#555',
  },
  contactItem: {
    margin: '4px 0',
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '13px',
  },
  contactLabel: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '12px',
    color: '#1A5C8C',
    lineHeight: '13px',
  },
  contactValue: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    color: '#555',
    lineHeight: '13px',
  },
  separator: {
    width: '1px',
    height: '60px',
    background: '#B5D3FF',
    alignSelf: 'center',
  },
  poweredBy: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  poweredByText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    fontSize: '12px',
    color: '#555',
    marginTop: 0,
    marginBottom: '8px',
    lineHeight: '15px',
  },
  cditLogo: {
    height: '18px',
    objectFit: 'contain',
  },
};

export default Footer;