import React from "react";
import { Card, Typography, Image, Space } from "antd";
import { useLocation } from "react-router-dom";
import feedbackSuccesImg from "../../assets/feedback_success.png";

const { Title, Text } = Typography;

const FeedbackSuccessPage: React.FC = () => {
  const location = useLocation();
  const { title, description, img } = location.state || {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        padding: "16px",
      }}
    >
      <Card
        style={{
          width: "75%",
          maxWidth: 600,
          textAlign: "center",
          borderRadius: 16,
          padding: 24,
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Space direction="vertical" size="large">
          <Image src={img || feedbackSuccesImg} alt="Error" preview={false} width={160} />
          <Title level={4} style={{ color: "#1890ff" }}>{title || "Gửi thành công"}</Title>
          <Text>{description || "Cảm ơn những ý kiến bạn đã đóng góp. Chúng tôi sẽ ghi nhận và sớm khắc phục"}</Text>
        </Space>
      </Card>
    </div>
  );
};

export default FeedbackSuccessPage;
