import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import FeedbackErrorPage from "../pages/feedback/FeedbackErrorPage";
import FeedbackSuccessPage from "../pages/feedback/FeedbackSuccessPage";

const NotFoundRedirect: React.FC = () => {
  console.log("Trang không tồn tại:", window.location.href);
  return <Navigate to="/qr/error" replace />;
};

const RedirectHandler: React.FC = () => {
  const currentURL = window.location.href;
  React.useEffect(() => {
    if (
      currentURL.startsWith("ezwater.vn") ||
      currentURL.startsWith("https://ezwater.vn") ||
      currentURL.startsWith("http://ezwater.vn")
    ) {
      window.location.href = "/water.html";
    } else {
      window.location.href = "/work.html";
    }
  }, []);

  return null;
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Chuyển hướng từ "/" đến "/work.html" bằng cách tải lại trang */}
        <Route path="/" element={<RedirectHandler />} />
        <Route path="/qr/:qr" element={<FeedbackPage />} />
        <Route path="/qr/error/" element={<FeedbackErrorPage />} />
        <Route path="/qr/success/" element={<FeedbackSuccessPage />} />
        <Route path="/qr/*" element={<NotFoundRedirect />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
