import React from "react";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./routes";

const App: React.FC = () => {
  return (
    <>
    <AppRoutes />
    <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
    
  );
};

export default App;
