import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Rate,
  Upload,
  Typography,
  Image,
  Spin,
} from "antd";
import type { UploadFile, UploadProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import feedbackService from "../../services/feedbackService";
import logoEzWork from "../../assets/ezwork.png";
import imageCompression from "browser-image-compression";
import add_image from "../../assets/image-add-02.svg";
import Footer from "./Footer";

const { Text } = Typography;

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const FeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const { qr } = useParams<{ qr: string }>();
  const [form] = Form.useForm();
  const [rating, setRating] = useState<number>(5);
  const [comment, setComment] = useState<string>("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [areaData, setAreaData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Lỗi khi nén ảnh:", error);
      return file;
    }
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const fileArray = await Promise.all(
        fileList.map(async (file) => {
          if (!file.originFileObj) return null;

          const compressedFile = await compressImage(file.originFileObj);
          const base64String = await getBase64(compressedFile);

          return {
            file_name: file.name,
            file_base64: base64String.split(",")[1],
          };
        })
      );

      const data: any = await feedbackService.insertFeedback({
        company_id: areaData?.area?.company_id,
        point: rating,
        area_id: areaData?.area?.are_id,
        feedback: comment,
        data: fileArray,
      });

      if (data.code === 200) {
        form.resetFields();
        setRating(5);
        setComment("");
        setFileList([]);
        navigate("/qr/success", {
          state: {
            title: "Gửi thành công!",
            description:
              "Cảm ơn những ý kiến bạn đã đóng góp. Chúng tôi sẽ ghi nhận và sớm khắc phục",
          },
        });
      } else {
        navigate("/qr/error", {
          state: {
            title: "Gửi không thành công!",
            description:
              data.description || "Có lỗi xảy ra, vui lòng thử lại sau.",
          },
        });
      }
    } catch (error) {
      navigate("/qr/error", {
        state: {
          title: "Gửi không thành công!",
          description: "Không thể kết nối tới server. Vui lòng thử lại sau.",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (qr && qr !== "0") {
      setLoading(true);
      feedbackService
        .getAreaByCode(qr)
        .then((data: any) => {
          // console.log(data);
          if (data.code !== 200) {
            navigate("/qr/error", {
              state: {
                title: "Vui lòng thử lại sau!",
                description: `${data.description}`,
              },
            });
          } else {
            // console.log(data);
            setAreaData(data);
          }
        })
        .catch(() => {
          navigate("/qr/error", {
            state: {
              title: "Vui lòng thử lại sau!",
              description:
                "Không thể kết nối tới server. Vui lòng thử lại sau.",
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [qr, navigate]);

  return (
    <Spin spinning={loading} size="large">
      <div style={styles.container}>
        <div style={styles.feedbackForm}>
          <div>
            {areaData?.area && areaData.area != null && (
              <>
                <div style={styles.logoContainer}>
                  <img
                    src={areaData?.area?.logo_url?.trim() || logoEzWork}
                    alt="ezWork Logo"
                    style={styles.logo}
                  />
                </div>

                <div style={{ padding: "24px 24px 0 24px" }}>
                  <div style={styles.infoSection}>
                    <div style={styles.infoItem}>
                      <Text style={styles.infoLabel}>
                        Đơn vị:{" "}
                        <Text style={styles.infoValue}>
                          {areaData?.area?.real_company_name}
                        </Text>
                      </Text>
                    </div>
                    <div style={styles.infoItem}>
                      <Text style={styles.infoLabel}>
                        Khu vực:{" "}
                        <Text style={styles.infoValue}>
                          {areaData?.area?.are_name}
                        </Text>{" "}
                      </Text>
                    </div>
                  </div>

                  <Form
                    requiredMark={false}
                    form={form}
                    name="feedback_form"
                    onFinish={onFinish}
                    layout="vertical"
                    style={styles.form}
                    initialValues={{ rating: rating }}
                  >
                    {/* Đánh giá sao */}
                    <Form.Item
                      label={
                        <Text style={styles.formLabel}>
                          Đánh giá của bạn{" "}
                          <span style={{ color: "red" }}> *</span>
                        </Text>
                      }
                      name="rating"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn đánh giá sao!",
                        },
                      ]}
                    >
                      <Rate
                        value={rating}
                        onChange={(value) => setRating(value)}
                        style={styles.rate}
                      />
                    </Form.Item>

                    {/* Nhận xét */}
                    <Form.Item
                      label={
                        <Text style={styles.formLabel}>Ý kiến của bạn</Text>
                      }
                    >
                      <Input.TextArea
                        value={comment}
                        onBlur={(e) => e.target.blur()}
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                        placeholder="Nếu bạn có ý kiến hoặc nhận xét gì, hãy viết ở đây...."
                        style={styles.input}
                      />
                    </Form.Item>

                    {/* Thêm ảnh */}
                    <Form.Item
                      label={<Text style={styles.formLabel}>Thêm ảnh</Text>}
                    >
                      <div style={styles.uploadContainer}>
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          beforeUpload={(file) => {
                            if (!file.type.startsWith("image/")) {
                              alert("Chỉ được tải lên ảnh!");
                              return false;
                            }
                            return false;
                          }}
                          style={{ backgroundColor: "red" }}
                        >
                          {fileList.length >= 3 ? null : (
                            <img
                              src={add_image}
                              alt="Add image"
                              style={styles.addImageButton}
                            />
                          )}
                        </Upload>
                      </div>
                    </Form.Item>

                    {/* Xem trước ảnh */}
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}

                    {/* Nút gửi đánh giá */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          style={styles.submitButton}
                        >
                          Gửi đánh giá
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </Spin>
  );
};

// Định nghĩa styles
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#F1F2F8",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    padding: "25px",
  },
  feedbackForm: {
    backgroundColor: "white",
    borderRadius: 20,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.06)",
    maxWidth: "600px",
    position: "relative",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 24,
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  infoItem: {
    display: "flex",
    marginBottom: 8,
  },
  infoLabel: {
    fontSize: 18,
    fontWeight: 500,
    color: "#000",
    marginRight: 6,
  },
  infoValue: {
    fontSize: 18,
    fontWeight: 600,
    color: "#2196F3",
  },
  form: {
    width: "100%",
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: "#333",
  },
  rate: {
    fontSize: 36,
    color: "#FADB14",
  },
  input: {
    width: "100%",
    borderRadius: 12,
    border: "1px solid #E0E0E0",
    padding: 12,
    fontSize: 14,
    resize: "none",
  },
  uploadContainer: {
    display: "flex",
    gap: 10,
  },
  addImageButton: {
    width: "50%",
    height: "50%",
    objectFit: "contain",
    padding: 0,
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  plusIcon: {
    fontSize: 24,
    color: "#999",
  },
  submitButton: {
    backgroundColor: "#2E7CF6",
    borderColor: "#E0E0E0",
    color: "#f8f8f8",
    height: 48,
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 24,
    marginTop: 16,
    boxShadow: "none",
    padding: "0 64px",
  },
};

export default FeedbackPage;
