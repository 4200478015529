import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const feedbackService = {
  insertFeedback: async (feedbackData: any) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/feedback/insert_feedback`, feedbackData, {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      });
      return response.data;
    } catch (error) {
      console.error("Lỗi khi gửi feedback:", error);
      throw error;
    }
  },

  getAreaByCode: async (code: string) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/area/getareabycode`,
        { are_code: code },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Lỗi khi lấy dữ liệu khu vực:", error);
      throw error;
    }
  },
};

export default feedbackService;
